.packages-container {
    width: 100%;
    margin: 100px auto;
}

.packages-container>h1 {
    text-align: center;
    margin: 0 auto;
}

.package-container {
    padding-top: 60px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    /* align-items: center; */
    width: 100%;
    /* height: 600px; */
    border-radius: 10px;
    overflow: hidden;
    gap: 30px;
}

.package-item {

    flex: 0 0 30%;
    height: 100%;
    text-align: center;
    /* border: 10px solid;
    border-image-slice: 1;
    border-width: 5px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */

    border: double 7px transparent;
    border-radius: 30px;
    background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)),
        linear-gradient(to left, #743ad5,
            #d53a9d);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

.package-item img {
    width: 63%;
    /* transform: rotate(-10deg); */
    margin: 30px auto;
    display: block;
}

.package-item>div {
    padding: 0 30px 30px;
    display: flex;
    flex-direction: column;
    height: max-content;

}

.package-item>div .content {
    margin: 20px 0 25px;
}

.package-item>div .content span:not(:last-child) {
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-width: 1px;
    border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.package-item span {
    display: block;
    text-align: center;
    color: #fff !important;
}

.package-item button {
    margin: auto 0 0 !important;
    border: none;
    background: -webkit-linear-gradient(left, #743ad5,
            #d53a9d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 42px;
    justify-content: space-between;
    padding: 5px;
    font-weight: 500;
}

.package-item button:hover {
    background: -webkit-linear-gradient(left, #743ad5,
            #d53a9d);
    color: #fff !important;
    -webkit-text-fill-color: #fff;
}

.package-item>div h2 {
    font-weight: 500;
    font-size: 36px;
}

@media only screen and (max-width: 1000px) {
    .packages-container>h1 {
        text-align: center;
        margin: 0 auto;
        font-size: 40px;
    }

    .package-container {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        max-width: 500px;
    }
}

@media only screen and (max-width: 700px) {
    .packages-container>h1 {
        text-align: center;
        margin: 0 auto;
        font-size: 30px;
    }

    .package-container {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        max-width: 500px;
    }
}