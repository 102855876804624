.pc-conatiner {
    text-align: center;
}

.pc-conatiner>h1 {
    text-align: center;
    margin: 50px auto 0;
}

.pc-conatiner img {
    width: 45%;
}