.header{
    width: 100%;
    height: 400px;
    overflow: hidden;
    border-radius: 0 0 15px 15px;
}
.header > img{
    width: 100%;
    height: auto;

}