@charset "UTF-8";
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400';

.player .timeline .controllers .back,
.player .timeline .controllers .play,
.player .timeline .controllers .forward,
.player .timeline .controllers,
.player .timeline .volume,
.player .head .infos,
.player .head .front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotation {
  display: block;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px -150px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.3;
  z-index: -1;
  animation: 2s girandomt infinite forwards linear;
}

.player {
  margin: 100px 0;
  border-radius: 6px;
  background-color: white;
  width: 100%;
  min-height: 200px;
  box-shadow: 0px 5px 20px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.player .head {
  padding: 15px;
  color: white;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.player .head .front {
  position: relative;
  height: 100%;
  justify-content: space-around;
}

.player .head .back {
  height: 110%;
  width: 110%;
  top: -10px;
  left: -10px;
  position: absolute;
  background-position: center;
  background-size: cover;
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.player .head .avatar {
  border-radius: 0 !important;
  width: 300px;
  height: max-content;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.player .head .avatar img {
  width: 100%;
  height: 100%;
}

.player .head .infos {
  justify-content: start;
  flex-direction: column;
  height: inherit;
  align-items: baseline;
  gap: 30px;
}

.player .head .tags span {
  border-radius: 3px;
  padding: 2px 11px;
  margin: 1px 2px;
  background-color: #5a15a5;
  font-weight: bold;
}

.player .head .titulo_song {
  font-size: 48px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.player .head .duracao_song {
  font-size: 12px;
  color: white;
  margin-bottom: 8px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.75);
}

.player .timeline {
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.player .timeline .volume {
  flex-direction: row;
}

.player .timeline .controllers.active .play {
  box-shadow: 0px 0px 10px 2px rgba(30, 177, 150, 0.1);
  animation: 3s pulseshadowplay infinite both;
}

.player .timeline .controllers .back,
.player .timeline .controllers .play,
.player .timeline .controllers .forward {
  font-size: 16px;
  margin: 10px;
  color: #4A4A4A;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.player .timeline .controllers .play {
  transition: all 0.3s ease-in-out;
}

.player .timeline .controllers .play:hover {
  box-shadow: 0px 0px 10px 2px#5a15a5;
  border: 2px solid #5a15a5 !important;
}

/* .player .timeline .controllers .back::after {
  content: "";
  font-family: "FontAwesome";
  margin-right: 5px;
}
.player .timeline .controllers .play::after {
  content: "";
  font-family: "FontAwesome";
  margin-left: 5px;
}
.player .timeline .controllers .forward::after {
  content: "";
  font-family: "FontAwesome";
  margin-left: 5px;
} */
.player .timeline .soundline {
  width: 100%;
  height: 6px;
  position: relative;
  background: #F3F3F3;
  border-radius: 2px;
  overflow: hidden;
}

.player .timeline .soundline::after {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background: #5a15a5;
  box-shadow: 0px 0px 9px #5a15a5;
  transition: all 0.4s cubic-bezier(0.07, 0.82, 0.11, 1.02);
  animation: 20s soundline infinite both linear;
}

@keyframes pulseshadowplay {
  0% {
    box-shadow: 0px 0px 10px 2px#5a15a5;
  }

  50% {
    box-shadow: 0px 0px 50px 2px #5a15a5;
  }
}

@keyframes soundline {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes girandomt {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.8);
  }

  100% {
    box-shadow: 0px 0px 30px 40px rgba(255, 255, 255, 0);
  }
}

@media only screen and (max-width: 768px) {
  .player .head .front {
    flex-direction: column;
  }

  .player .head .titulo_song {
    font-size: 20px;
  }

  .player .head .avatar {
    overflow: initial;
    flex: 1 1 100%;
  }

  .player .head .infos {
    gap: 0;
    justify-content: end;
  }
}