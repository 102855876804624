* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Open Sans', sans-serif; */
    /* /* font-family: 'Roboto', sans-serif; */
    font-family: 'Ubuntu', sans-serif;
}

.InputContainer .InputElement {
    background-color: #fff;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #19092e;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #280e49;
}

:root {
    --color-orange: #feb74f;
    --color-pink: #ff2c58;
    --color-red: #f75338;
    --color-blur: #4d8df9;
    --color-purple: #5E58EC;
}

html,
body {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* background: #a12cf0; */
    background: #000;
    color: #fff;
}

.main {
    min-height: 100vh;
}

.container {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
}

h6 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
}

h5 {
    color: #cbcbcb;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
}

h4 {
    color: #dfdfdf;
    font-size: 36px;
    font-weight: 700;
    line-height: 30px;
}

span {
    color: rgba(255, 255, 255, 0.712);
}

button {
    background-color: transparent;
    border: 3px solid var(--color-red);
    border-radius: 15px;
    padding: 10px 15px;
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    margin: 10px 10px 0 !important;
}

.card-1-3 {
    flex-basis: 32%;
}

/* button:hover {
    background-image: linear-gradient(to left, var(--color-orange), var(--color-pink));
} */

.title-container {
    margin: 20px 0;
    font-weight: 800;
    font-size: 40px;
}

.title-container>h2 {
    font-size: 40px;
    font-weight: 800;
}

.title-big-color {
    font-size: 64px;
    font-weight: 1000;
    background: -webkit-linear-gradient(left, var(--color-pink), var(--color-orange));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 50px 0;
    width: max-content;
}

.text-color {
    background: -webkit-linear-gradient(left, var(--color-pink), var(--color-orange));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.line {
    width: 100%;
    height: 8px;
    margin: 10px 0 20px;
    border-radius: 5px;
    background-image: linear-gradient(to left, var(--color-orange), var(--color-pink));
}

.interface-grid {
    background-color: #450074 !important;
}

.sortable-play-list .list-item-container {
    background-color: #ff9900 !important;
}

.loader {
    width: 50px;
    height: 100%;
    display: inline-block;
}

.checkout {
    width: 50%;
    width: 90%;
    max-width: 500px;
    margin: 100px auto;
}

.checkout-button {
    background-color: #24216d;
    border: 1px solid #efc078;
    border-radius: 8px;
    margin: 20px 0 !important;
    font-size: 20px;
    font-weight: 500;
}

.checkout-button:hover {
    background-color: #1b194e;
    background-image: none;
}

.empty-message-container {
    width: 100%;
    height: calc(100vh - 190px);

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.input-container {
    display: flex;
    margin: 10px auto;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.input-container label {
    font-size: 24px;
    margin-right: 10px;
    line-height: 30px;
    width: 20%;
}

.input-container input {
    width: 100%;
    font-size: 22px;
    margin-right: 10px;
    padding: 12px;
    border-radius: 15px;
}

input[type=submit] {
    cursor: pointer;
}

input:focus {
    outline: none;
}

.popupMenu {
    position: fixed;
    bottom: 50px;
    right: 50px;
    background-color: #450074;
    border-radius: 50px;
    padding: 20px 20px 15px;
    color: #fff;
    font-weight: 700;
    box-shadow: 0px 0px 10px 0px #000;
    cursor: pointer;
}

.popupMenu a {
    text-decoration: none;
}

.popupMenu span {
    color: #fff;
}

.popupMenu svg {
    font-size: 24px;
    color: #fff;
    margin: 0px 10px 2px 0;
}

@media only screen and (max-width: 600px) {}


@media only screen and (max-width: 768px) {
    .card-1-3 {
        flex-basis: 100%;
    }

    .title-container>h2 {
        font-size: 30px;
        font-weight: 800;
    }

    .input-container {
        padding: 0 5px;
        gap: 10px;
    }

    .input-container label {
        font-size: 18px;
    }

    .input-container input {
        font-size: 18px;
        padding: 10px 10px;
    }

}

@media only screen and (max-width: 480px) {
    .title-container>h2 {
        font-size: 20px;
        font-weight: 500;
    }

    .title-big-color {
        font-size: 20px;
    }
}


@media only screen and (max-width: 992px) {}


@media only screen and (max-width: 1200px) {}