.blog-container {
    /* width: 100%; */
}

.blog-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.blog-item {
    background-color: #44a8ff1a;
    padding: 15px 15px 15px;
    border-radius: 15px;
    flex: 0 1 48%;
    margin: 50px 0;
}

.blog-item h1 {
    margin-bottom: 10px;
    color: #d8e6fe;
    font-size: 24px;
    font-weight: 700;
}

.blog-item .blog-desc * {
    font-size: 22px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.822);
}

.blog-item>img {
    width: 100%;
    min-height: 200px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 5px;
}

.blog-desc {
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.822);
    padding: 10px 0;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.blog-item>a:last-child {
    margin: 10px 0 0 !important;
    border: 1px solid #003052;
    appearance: auto;
    font-weight: 500;
    font-size: 19px;
    padding: 10px 14px;
    border-radius: 15px;
    color: #fff;
}

.blog-item>a:hover {
    background-color: #003052;
}

.author-avatar {
    display: flex;
    margin: 10px 0 10px;
    gap: 20px;
}

.author-avatar img {
    display: flex;
    width: 50px;
    height: 50px;
    border-radius: 100%;
}

.author-avatar div h3 {
    background: -webkit-linear-gradient(left, var(--color-pink), var(--color-orange));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
    font-weight: 700;
}


.singleBlog-container {
    width: 80%;
    margin: 0 auto 100px;
}

.singleBlog-container>img {
    width: 100%;
    border-radius: 8px;
    height: 300px;
    object-fit: cover;

}


.addBlog-container form {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 100px;
}

.addBlog-container form input {
    width: 768px;
    font-size: 22px;
    margin-right: 10px;
    padding: 12px;
    border-radius: 15px;
}



@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    .blog-item {
        flex: 1 0 100%;
    }

    .singleBlog-container {
        width: 95%;
        margin: 0 auto 100px;
    }

    .blog-item h1 {
        font-size: 20px;
    }

    .blog-item>img {
        height: 150px;
    }

}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 480px) {}