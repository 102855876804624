nav {
    width: 100%;
    background-color: var(--color-purple);
    padding: 10px 0;
    box-shadow: 0px 0px 20px #00000052;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 100%; */
}

#logo {
    height: 80px;
    cursor: pointer;
}

#logo img {
    height: 100%;
}

#logo video {
    height: 100%;
    mix-blend-mode: lighten;
}

#icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#icons svg:hover {
    scale: 1.3;
}

#icons svg {
    fill: #fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .1s ease-out;
}

.white-path path {
    fill: #fff;
}

#menu-icon svg {
    fill: #fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#menu-icon {
    display: none;

}

.mobile-nav {
    width: 100%;
    height: 80px;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
}

.mobile-nav svg {
    fill: #fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.notification-icon {
    position: absolute;
    background-color: tomato;
    border-radius: 100%;
    padding: 5px;
    font-size: 15px;
    line-height: 10px;
    font-weight: 900;
    color: #fff;
    bottom: 0;
    right: 0;
}



@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 768px) {
    #icons {
        display: none;
    }

    #menu-icon {
        display: block;
    }
}

@media only screen and (max-width: 480px) {}

@media only screen and (max-width: 600px) {}



/* @media only screen and (max-width: 480px) {}


@media only screen and (max-width: 600px) {}


@media only screen and (max-width: 768px) {}


@media only screen and (max-width: 992px) {}


@media only screen and (max-width: 1200px) {} */