.grid-container {

    margin: 0 auto;
    height: max-content;
    display: flex;
    overflow-y: hidden;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;

}

.product-grid {
    margin: 80px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

}

.product-grid>div {
    flex: 0 0 250px;
}

.product-card {
    width: 300px;

    color: #fff;
    padding: 10px;
    border-radius: 15px;

    align-self: normal;
    cursor: pointer;
}

.round {
    height: 100vh;
    margin-top: 50px;
}

.product-card:nth-child(1) {
    right: 100px;

}

/* .product-card{
    width: 300px;
    height: 300px;
    transform: translate(-50%);
    position: absolute;
}
.product-card{
    width: 300px;
    height: 300px;
    color: #fff;
    padding: 10px;
    border-radius: 15px;

    align-self: normal;
    
} */
.product-card video {
    width: 100%;
}

.product-card_details {

    padding: 20px;
}

.product-viewer {
    width: 100%;
    height: 300px;
}


@media only screen and (max-width: 480px) {}


@media only screen and (max-width: 600px) {}


@media only screen and (max-width: 768px) {
    .grid-container {
        margin: 50px 0;
    }

    .product-grid>div {
        flex: 0 0 50%;
    }

    .product-card {
        width: 200px;
    }
}


@media only screen and (max-width: 992px) {}


@media only screen and (max-width: 1200px) {}